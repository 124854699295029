import { gsap } from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export function heroTimeline(size: "base" | "small" | "large") {
  const sizeId = size === "large" ? "-lg" : size === "small" ? "-sm" : "";

  const tl = gsap.timeline();

  // tl.to(MorphSVGPlugin.convertToPath(`#hero-start${sizeId}`), {
  //   morphSVG: `#hero-mid${sizeId}`,
  // })
  //   .to(
  //     MorphSVGPlugin.convertToPath(`#hero-start${sizeId}`),
  //     {
  //       morphSVG: `#hero-end${sizeId}`,
  //     },
  //     0.4,
  //   )
  tl.fromTo(
    `#hero${sizeId}`,
    {
      clipPath: "inset(0 100% 100% 0)",
    },
    {
      clipPath: "inset(0 0% 0% 0)",
      duration: 0.8,
    },
    0.25,
  ).from(
    `#hero-subtitle${sizeId}`,
    {
      opacity: 0,
      y: 10,
    },
    1,
  );
}
