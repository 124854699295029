import { gsap } from "gsap";
import MorphSVGPlugin from "gsap/MorphSVGPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/SplitText";
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export function yourPartnersHeaderTimeline(size: "base" | "small" | "large") {
  const sizeId = size === "large" ? "-lg" : size === "small" ? "-sm" : "";

  const headerTl = gsap.timeline({
    scrollTrigger: {
      trigger: "#your-partners-morph-svg-container",
    },
  });

  headerTl
    .to(MorphSVGPlugin.convertToPath(`#your-partners-start${sizeId}`), {
      morphSVG: `#your-partners-mid${sizeId}`,
    })
    .to(
      MorphSVGPlugin.convertToPath(`#your-partners-start${sizeId}`),
      {
        morphSVG: `#your-partners-end${sizeId}`,
      },
      0.25,
    )
    .fromTo(
      "#your-partners-man-container",
      {
        clipPath: "inset(0% 0% 100% 0%)",
      },
      {
        clipPath: "inset(0% 0% 0% 0%)",
        duration: 0.8,
        ease: "power1.out",
      },
      0.3,
    )
    .from(
      new SplitText(`#your-container${sizeId}`).chars,
      {
        opacity: 0,
        y: size === "base" ? 20 : 50,
        ease: "back(2)",
        stagger: {
          from: "start",
          each: 0.03,
        },
      },
      0.5,
    )
    .from(
      new SplitText(`#partners-container${sizeId}`).chars,
      {
        opacity: 0,
        y: size === "base" ? 20 : 50,
        ease: "back(2)",
        stagger: {
          from: "start",
          each: 0.03,
        },
      },
      0.65,
    )
    .from(
      new SplitText("#in-sustainable-building-finance").chars,
      {
        opacity: 0,
        y: 20,
        ease: "back(2)",
        stagger: {
          from: "start",
          each: 0.03,
        },
      },
      0.89,
    );
}

export function yourPartnersTextTimeline() {
  const textTl = gsap.timeline({
    delay: 1.4,
    scrollTrigger: {
      trigger: "#your-partners-morph-svg-container",
    },
  });

  textTl
    .from("#tech-enhanced-underwriting", {
      opacity: 0,
      y: 24,
      ease: "power1.out",
    })
    .from("#sustainable-building-finance-standards", {
      opacity: 0,
      y: 24,
      ease: "power1.out",
    });
}
